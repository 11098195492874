/* eslint-disable react-hooks/rules-of-hooks */
import '@/styles/globals.css';
import React from 'react';
import type { AppProps } from 'next/app';
import { store } from '../stores/store';
import '../styles/atcui.scss';
import '../styles/index.scss';
import {
  Provider as FeatureFlagsContextProvider,
} from 'reaxl-features';
import { Provider as ReduxProvider } from 'react-redux';
import optimizelyClient from '../utilities/optimizelyClient';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { AnalyticsEngineConfigured} from '@/analytics/analyticsEngine';
import consumerId from '../utilities/myConsumerId';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{ id: consumerId }}
    >
      <ReduxProvider store={store}>
        <AnalyticsEngineConfigured />
        <FeatureFlagsContextProvider>
          <Component {...pageProps} />
        </FeatureFlagsContextProvider>
      </ReduxProvider>
    </OptimizelyProvider>
  );
}
